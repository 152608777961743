import React from 'react';
import { graphql } from 'gatsby';

import Page from 'components/content/products';

const AISP = ({ data: { pages: { edges } } }) => <Page edges={edges} />

export default AISP;

export const query = graphql`
    query AispProductsQuery ($path: String!) {
        ...MarkdownPages
    }
`